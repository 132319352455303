import React from 'react'
import Admissions from '../components/admissions/Admissions';

const AdmissionsPage = () => {
    return (
        <div>
          <Admissions />
        </div>
    )
}

export default AdmissionsPage;
