import React from 'react'
import About from '../components/about/About';

const AboutPage = () => {
    return (
        <div>
          <About />
        </div>
    )
}

export default AboutPage;
