export const MenuItems = [
  {
    title: "Enroll",
    path: "/admissions",
    cName: "main-dropdown-link",
  },
  {
    title: "Prospectus",
    path: "/admissions",
    cName: "main-dropdown-link",
  },
  {
    title: "Calendar",
    path: "/admissions",
    cName: "main-dropdown-link",
  },
];
