export const AboutMenu = [
  {
    title: "Our History",
    path: "/about",
    cName: "main-dropdown-link",
  },
  {
    title: "Staff",
    path: "/about",
    cName: "main-dropdown-link",
  },
  {
    title: "Anthem",
    path: "/anthem",
    cName: "main-dropdown-link",
  },
];
