import React from 'react'
import Contact from '../components/contact/Contact';

const Contacts = () => {
    return (
        <div>
          <Contact />
        </div>
    )
}

export default Contacts;
