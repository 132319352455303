
import React from 'react'
import Portal from '../components/portal/Portal';

const PortalPage = () => {
    return (
        <div>
          <Portal />
        </div>
    )
}

export default PortalPage;
